.form__row {
  @media (--gte-tablet) {
    display: flex;

    > .input,
    > .form__column {
      flex: 1;
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
