@define-mixin checkbox {
  /*
    Previously we used "display: none;" here, but due to some very weird shit in the Draft editor
    lib, we have to use this:
  */
  opacity: 0;
  width: 0;
  position: fixed;

  + label {
    margin: 0;
    position: relative;
    cursor: pointer;
    text-transform: none;

    &::before {
      content: '';
      font-size: 15px;
      color: var(--primary-color);
      width: 19px;
      height: 19px;
      background-color: var(--input-background);
      border: 1px solid var(--input-border-color);
      transition: var(--input-transition);
      display: inline-block;
      border-radius: 3px;
      position: relative;
      top: 4px;
      margin-right: 10px;
    }

    &:hover::before {
      border: 1px solid var(--input-hover-border-color);
    }
  }

  &:disabled + label {
    cursor: initial;

    &:hover::before {
      border: 1px solid var(--input-border-color);
    }
  }

  &:checked + label {
    &::before {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
    }

    &::after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 10px;
      border: solid var(--white);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
      top: 7px;
      left: 7px;
    }
  }
}

@define-mixin checkboxAfter {
  + label {
    padding-right: 30px;

    &::before {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 0;
    }
  }

  &:checked + label::after {
    right: 7px;
    top: 3px;
    left: unset;
  }
}
