.paginationContainer {
  border-top: 1px solid var(--gray4);
  padding-top: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column-reverse;

  @media (--gte-tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  > div:last-child {
    @media (--lt-tablet) {
      padding-bottom: 10px;
    }
  }
}

.pagination {
  display: flex;

  @media (--lte-tablet) {
    margin-right: 10px;
  }

  > span {
    display: flex;
    margin-right: 5px;

    a {
      border-radius: 4px;
      background-color: var(--gray3);
      color: var(--body-font-color);
      padding: 0.2em 0.6em;
      display: inline-flex;
      align-items: center;
      font-weight: 500;

      &:hover {
        background-color: var(--gray4);
      }
    }
  }

  .current {
    padding: 0.2em 0.6em;
    align-items: center;
    display: inline-flex;
    font-weight: 500;
    background-color: var(--white);
    color: var(--body-font-color);
    border-radius: 4px;
  }

  i {
    margin-right: 0;
  }
}
