.align-right {
  text-align: right;
}

.center {
  text-align: center;
}

.indent {
  margin-left: 10px;
  padding-left: 12px;
  border-left: 1px solid var(--gray5);

  @media (--gte-largephone) {
    margin-left: 16px;
    padding-left: 16px;
  }
}
