@define-mixin input {
  @mixin field;
  display: block;
  padding: 0.5rem;
  /* Reset unusual Firefox-on-Android default style; see
  https://github.com/necolas/normalize.css/issues/214. */
  background-image: none;
  background-clip: padding-box;

  &::placeholder {
    @mixin input__placeholder;
  }
}

@define-mixin input__placeholder {
  color: var(--gray5);
  /* Override Firefox's unusual default opacity; see
  https://github.com/twbs/bootstrap/pull/11526. */
  opacity: 1;
}

@define-mixin input-chromeless {
  background: transparent;
  border: none;
  appearance: none;
  outline: none;
  width: inherit;
  display: inline-block;
  height: inherit;
  resize: none;
  line-height: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  text-shadow: inherit;
  padding: 0;

  &:hover:not(:disabled) {
    border-color: none;
  }

  &:focus {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
    outline: none;
  }
}
