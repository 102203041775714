.shadowed-box_list {
  list-style: none;
  padding: 0;

  li {
    border-bottom: 1px solid var(--gray3);
    padding: 10px 16px;

    i {
      margin-right: 10px;
      color: var(--purple5);
    }
  }
}
