#pageLoader {
  display: none;
  overflow: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;

  div {
    height: 4px;
    width: 100%;
    background: var(--green2);

    &:before {
      content: '';
      display: block;
      background-color: var(--dark-orange);
      height: 4px;
      width: 0;
      animation: getWidth 3s ease-out infinite;
    }
  }
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}
