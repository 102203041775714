.shadowed-box_linked-list {
  list-style: none;
  padding: 0;

  li {
    border-bottom: 1px solid var(--gray3);

    a {
      padding: 10px 16px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--gray1);
      }

      > div {
        flex: 1;

        &:nth-child(2) {
          padding-left: 15px;
          flex: none;
        }
      }

      h3 {
        margin: 0;

        small {
          padding-left: 20px;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }
}
