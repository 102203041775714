@define-mixin page-title {
  margin: 0 auto 30px;

  h1 {
    font-weight: 100;
    font-size: 20px;
    color: var(--purple5);
    padding: 0 2px 8px;
    border-bottom: 1px solid var(--gray3);

    @media (--gte-tablet) {
      padding-bottom: 0;
    }

    @media (--gte-tablet) and (--lt-desktop) {
      font-size: 26px;
    }

    small {
      color: var(--gray6);
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      display: block;

      @media (--gte-tablet) {
        float: right;
        position: relative;
        top: 12px;
      }
    }
  }

  h2 {
    font-weight: 300;
    font-size: 17px;
    color: var(--purple1);

    small {
      color: var(--gray6);
      letter-spacing: 1px;
      text-transform: uppercase;

      @media (--gte-tablet) {
        float: right;
        position: relative;
        top: 2px;
      }
    }
  }
}
