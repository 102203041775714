.container {
  @mixin container;
}

.container_tablet-max,
.container-narrow {
  @mixin container;
  max-width: var(--tablet);
}

.container-box {
  @mixin container;
  border-radius: 3px;
  box-shadow: var(--block-shadow-min);
  background-color: var(--white);
}

.container-box__narrow {
  max-width: 660px;
}

.container-box__centered {
  text-align: center;
}

.container-box__inner {
  padding: 16px;
  position: relative;

  @media (--gte-largephone) {
    padding: 30px;
  }

  @media (--gt-largephone) {
    padding: 60px;
  }
}
