.container > .page-title {
  margin-top: 20px;

  @media (--gte-desktop) {
    margin-top: 30px;
  }
}

.page-title {
  @mixin page-title;

  .label {
    font-size: 0.5em;
  }
}
