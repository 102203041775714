@define-mixin button {
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 3px;
  appearance: none;
  transition: background-color ease-in-out 0.15s;
  cursor: pointer;
  box-shadow: var(--block-shadow-min);
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border: 1px solid transparent;

  &:hover {
    background-color: var(--link-hover-color);
    color: var(--white);
  }

  &:disabled {
    background-color: var(--gray4);
    font-weight: 300;
    cursor: not-allowed;
    color: var(--gray6);
    box-shadow: none;
  }
}

@define-mixin button__quiet {
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: none;

  &:disabled,
  &:hover {
    background-color: transparent;
    color: var(--link-hover-color);
  }
}

@define-mixin button-quiet {
  @mixin button;
  @mixin button__quiet;
}

@define-mixin button-transparent {
  @mixin button;
  background-color: transparent;
  color: var(--white);
  box-shadow: none;
  border: 1px solid var(--white);

  &:disabled,
  &:hover {
    background-color: var(--gray3);
    color: var(--primary-color);
  }
}

@define-mixin button-icon {
  @mixin button;
  background-color: transparent;
  color: var(--link-color);
  box-shadow: none;
  border: none;
  padding: 6px;
  width: min-content;
  height: min-content;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--link-hover-color);
    text-decoration: none;
    background-color: var(--gray4);
  }

  &:disabled {
    color: var(--gray6);
    background-color: transparent;

    &:hover {
      color: var(--gray6);
      background-color: transparent;
    }
  }

  i {
    margin: 0;
  }
}

@define-mixin button-link {
  @mixin button;
  @mixin button__link;
}

@define-mixin button__link {
  background-color: transparent;
  color: var(--link-color);
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  display: inline;
  line-height: inherit;
  font-size: inherit;

  &:hover,
  &:disabled {
    background-color: transparent;
    color: var(--link-hover-color);
    text-decoration: none;
  }

  &:disabled {
    font-weight: inherit;
    color: var(--gray5);
  }
}

/* Colors --------------------------------------- */

@define-mixin button__white {
  background-color: var(--white);
  color: var(--primary-color);
  box-shadow: none;
  border: 1px solid var(--primary-color);

  &:disabled,
  &:hover {
    background-color: var(--gray3);
    color: var(--primary-color);
  }
}

@define-mixin button__green {
  background-color: var(--green4);
  color: var(--white);
  box-shadow: none;
  border: 1px solid var(--green4);

  &:disabled,
  &:hover {
    background-color: var(--green5);
    color: var(--white);
  }
}

@define-mixin button__secondary {
  background-color: var(--secondary-color);
  color: var(--black);

  &:hover {
    background-color: var(--gray5);
    color: var(--black);
  }

  &:disabled {
    background-color: var(--gray3);
    color: var(--gray6);
  }
}

@define-mixin button-white {
  @mixin button;
  @mixin button__white;
}

@define-mixin button-green {
  @mixin button;
  @mixin button__green;
}

@define-mixin button-secondary {
  @mixin button;
  @mixin button__secondary;
}

/* Sizes ---------------------------------------- */

@define-mixin button__small {
  padding: 6px 18px;
  font-size: 15px;
}

@define-mixin button__mini {
  padding: 4px 18px;
  font-size: 14px;
}

@define-mixin button__tiny {
  padding: 4px 12px;
  font-size: 12px;
}

@define-mixin button-small {
  @mixin button;
  @mixin button__small;
}

@define-mixin button-mini {
  @mixin button;
  @mixin button__mini;
}

@define-mixin button-tiny {
  @mixin button;
  @mixin button__tiny;
}

@define-mixin button__loading {
  background: var(--gray3) svg-load('ajax-loader_horizontal_purple.svg') no-repeat center center;
  color: transparent;

  &:hover,
  &:disabled {
    background: var(--gray3) svg-load('ajax-loader_horizontal_purple.svg') no-repeat center center;
    color: transparent !important;
  }
}

@define-mixin button__fullWidth {
  width: 100%;
  text-align: left;
  padding-right: 20px;
  padding-left: 20px;

  i {
    margin-right: 10px;
  }

  :global i.right {
    float: right;
    margin-top: 2px;
    margin-right: 0;
  }
}
