@define-mixin field {
  color: var(--input-color);
  background-color: var(--input-background);
  /* height: var(--input-height); */
  font-size: var(--input-font-size);
  font-weight: 300;
  line-height: 1.25rem;
  width: 100%;
  border-radius: 2px;
  border: 1px solid var(--input-border-color);
  transition: var(--input-transition);

  &:hover:not(:disabled) {
    border-color: var(--input-hover-border-color);
  }

  &:focus {
    @mixin field__focus;
  }

  /*
    Disabled and read-only inputs

    HTML5 says that controls under a fieldset > legend:first-child won't be
    disabled if the fieldset is disabled. Due to implementation difficulty, we
    don't honor that edge case; we style them as disabled anyway.
  */
  &:disabled,
  &[readonly] {
    @mixin field__disabled;
  }
}

@define-mixin field__focus {
  color: var(--input-color);
  background-color: var(--input-background);
  border-color: var(--input-hover-border-color);
  outline: none;
}

@define-mixin field__disabled {
  background-color: var(--input-disabled-background);
  color: var(--input-disabled-color);
  cursor: not-allowed;
}

@define-mixin field__alt {
  --input-color: var(--input-alt-color);
  --input-background: var(--input-alt-background);
  --input-border-color: var(--input-alt-border-color);
  --input-hover-border-color: var(--input-alt-hover-border-color);
}

@define-mixin fieldError {
  font-size: 13px;
  margin-top: 3px;
  margin-left: 2px;
  color: var(--red);
}

@define-mixin fieldHint {
  font-size: 12px;
  color: var(--gray6);
  margin-top: 8px;
  margin-left: 2px;
  display: block;
}
