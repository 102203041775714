.action {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray3);
  border-left: 4px solid var(--green2);
  padding: 20px;
  background-color: var(--gray1);
  color: var(--black);

  @media (--gte-tablet) {
    flex-direction: row;
  }
}

.action_danger {
  border-left-color: var(--orange);
  background-color: var(--white);
}

.action_highlight {
  background-color: var(--white);
}

.action__text {
  @media (--gte-tablet) {
    flex: 1;
  }

  ul {
    font-size: 15px;
  }
}

.action__button {
  padding-top: 20px;

  @media (--gte-tablet) {
    padding: 10px 0 0 30px;
  }
}
