@import 'shadowed_box/dlist.css';
@import 'shadowed_box/link.css';
@import 'shadowed_box/linked_list.css';
@import 'shadowed_box/list.css';
@import 'shadowed_box/sectioned.css';

.shadowed-box {
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: var(--block-shadow-min);
}

.shadowed-box_faded {
  opacity: 0.6;
  box-shadow: none;
}
