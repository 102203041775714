@define-mixin select {
  @mixin field;
  appearance: none;
  /* stylelint-disable string-quotes */
  background: var(--input-background)
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  /* stylelint-enable string-quotes */
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  height: auto;

  /* Unstyle the caret on `<select>`s in IE10+. */
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus::-ms-value {
    /* Suppress the nested default white text on blue background highlight given to
    the selected option text when the (still closed) <select> receives focus
    in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    match the appearance of the native widget.
    See https://github.com/twbs/bootstrap/issues/19398. */
    color: var(--input-color);
    background-color: var(--input-background);
  }
}

@define-mixin select__mini {
  padding: 0.3rem 1.5rem 0.3rem 0.5rem;
  font-size: 14px;
}

@define-mixin select__plain {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  width: inherit;
  font-size: 1em;
  color: var(--black);

  &:focus {
    color: var(--black);
    background-color: transparent;
  }
}
