/* stylelint-disable declaration-no-important */
.shadowed-box_link {
  padding: 10px 16px;
  font-weight: 500;
  display: flex;
  margin-top: 10px;
  transition: background-color 0.15s;

  > section {
    flex: 1;
    align-self: center;

    &:last-child {
      flex: 0;
      padding-left: 10px;
    }

    img {
      border-radius: 6px;
      border: 2px solid var(--gray3);
      margin: 0 6px 0 -6px;
    }

    hr {
      margin: 8px 0;
    }
  }

  > i {
    line-height: 1;
  }

  small {
    display: block;
    font-weight: 300;
    color: var(--purple1);
  }
}

a.shadowed-box_link:hover {
  background-color: var(--gray1);
}

.shadowed-box_highlighted {
  background-color: var(--purple5);
  color: var(--white);
  transition: background-color 0.15s;

  &:hover {
    color: var(--white);
    background-color: var(--purple6) !important;
  }
}

.shadowed-box__link {
  border-radius: 3px;
  display: flex;
  padding: 10px 16px;
  border-top: 1px solid var(--gray4);
  transition: background-color 0.15s;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: var(--gray1);
  }

  > section {
    flex: 1;
    align-self: center;

    &:last-child {
      flex: 0;
      padding-left: 10px;
    }
  }

  + .shadowed-box__link {
    border-radius: 0;
  }
}
