.shadowed-box_sectioned {
  > section {
    border-top: 1px solid var(--gray3);
    padding: 12px;

    @media (--gte-tablet) {
      padding: 16px;
    }

    &:first-child {
      border-top: 0;
    }

    > i {
      float: right;
      color: var(--purple5);
    }

    .button_to + .button_to {
      margin-top: 5px;
    }

    h1 {
      margin: 0;
    }

    h4 {
      margin: 0;
      font-weight: 300;
    }
  }

  .shadowed-box_link {
    margin: 0;
  }
}
