@define-mixin container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--desktop);
  width: 98%;
}

@define-mixin container_tablet {
  @mixin container;
  max-width: var(--tablet);
}
