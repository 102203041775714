footer {
  background-color: var(--purple7);
  color: var(--white);
  padding: 60px 10px 30px;
  text-align: center;
  font-size: 13px;

  @media (--lte-tablet) {
    padding: 40px 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      &:not(:last-child)::after {
        content: '';
        border-right: 1px solid var(--purple2);
        padding-right: 12px;
        margin-right: 6px;
      }

      &.no-split::after {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  p {
    margin: 28px 0;
    opacity: 0.8;
    font-size: 12px;
  }

  a {
    color: var(--white);
    font-weight: 300;

    &:hover {
      color: var(--white);
      text-decoration: underline;
    }
  }

  svg {
    width: 16px;
    vertical-align: sub;
  }
}
