.form__actions {
  border-top: 1px solid var(--gray3);
  padding-top: 20px;
  display: flex;
  align-items: center;

  .button,
  [type='submit'],
  [type='reset'] {
    @media (--lt-tablet) {
      width: 100%;
    }
  }
}

.form__actions_min {
  padding-top: 14px;
  margin-top: 0;
}

.form__actions__choice {
  margin: 0 10px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;

  @media (--lt-tablet) {
    width: 100%;
    display: block;
    margin: 5px 0;
  }
}

.form__actions__with-next {
  @media (--lte-largephone) {
    display: block;
  }

  section {
    margin: 14px 0 0;

    @media (--gt-largephone) {
      margin: 0 0 0 10px;
    }
  }
}

.form__actions__message {
  margin-left: 30px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

.form__actions__message_success {
  color: var(--green4);
}
