[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'] {
  @mixin input;
}

textarea {
  @mixin textarea;
}

[type='checkbox'].boolean {
  @mixin checkbox;
}

[type='radio'] {
  @mixin radio;
}

select.select {
  @mixin select;
}

.button {
  @mixin button;
}

.input {
  margin-bottom: 20px;

  &.radio_buttons {
    > label {
      margin-bottom: 0.6rem;
      display: block;
    }

    .radio {
      margin-right: 16px;
      margin-left: 0;

      @media (--lt-tablet) {
        display: block;
      }
    }

    .collection_radio_buttons {
      margin-left: 0.4rem;
      font-size: 16px;
      text-transform: none;
    }
  }
}

.input__error {
  @mixin fieldError;
}

.input__hint {
  font-size: 13px;
  color: var(--gray6);
  margin-top: 8px;

  + .input__error {
    margin-top: 0;
  }
}
