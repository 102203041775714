.table {
  width: 100%;
  max-width: 100%;

  /* Force table to not be like tables anymore */
  &:not(.table_always-horizontal) {
    @media (--lte-largephone) {
      display: block;

      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }
  }

  &:not(.table_not-striped) tbody > tr {
    @media (--gt-largephone) {
      &:nth-of-type(odd) {
        background-color: darken(var(--gray1), 0.8%);
      }
    }
  }

  thead > tr > th,
  tbody > tr > th,
  tfoot > tr > th,
  thead > tr > td,
  tbody > tr > td,
  tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid var(--gray4);
  }

  thead > tr > th {
    vertical-align: bottom;
    border-top: none;
    border-bottom: 2px solid var(--gray4);
    color: var(--gray8);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 300;
    text-align: left;
  }

  tfoot > tr > th,
  tfoot > tr > td {
    vertical-align: bottom;
    border-top: 2px solid var(--gray4);
    border-bottom: 1px solid var(--gray4);
    color: var(--black);
    background-color: var(--gray2);
  }

  tbody > tr {
    background-color: var(--blue0);

    @media (--gt-largephone) {
      &:hover {
        background-color: var(--white);
      }
    }
  }

  &:not(.table_always-horizontal) td {
    @media (--lte-largephone) {
      &:last-child {
        margin-bottom: 20px;
        border-bottom: 1px solid var(--gray4);
      }
    }
  }

  td {
    &.nowrap {
      white-space: nowrap;
      padding-right: 20px;
    }

    &.nopadding {
      padding: 0;
    }

    blockquote {
      color: var(--gray9);
      font-size: 14px;
      margin: 10px 0;
    }
  }

  ul {
    margin: 0;
    padding-left: 20px;

    li {
      padding: 1px 0;
    }
  }
}

.table_small {
  font-size: 0.9em;
}

.table_dlist {
  tbody > tr > td:first-child {
    font-size: 14px;

    @media (--gt-largephone) {
      width: 80px;
    }
  }
}

.table_shadowed {
  border-radius: 3px;
  box-shadow: var(--block-shadow-min);

  tbody > tr > td {
    padding: 12px;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 3px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 3px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  tbody > tr {
    background-color: var(--white);
  }
}
