.large {
  font-size: 1.05rem;
}

.small {
  font-size: 0.9rem;
}

.smaller {
  font-size: 0.8rem;
}

.smallest {
  font-size: 0.75rem;
}

.bold {
  font-weight: 500;
}

.regular {
  font-weight: 300;
}

.thin {
  font-weight: 100;
}

.strikethrough {
  text-decoration: line-through;
}

.underlined {
  text-decoration: underline;
}
