.spaced {
  margin-bottom: 30px;
}

.spaced__above {
  margin-top: 30px;
}

.spaced__above_small {
  margin-top: 15px;
}

.spaced_small {
  margin-bottom: 15px;
}

.space__below_large {
  margin-bottom: 50px;
}

.space__none {
  margin: 0;
}
