.shadowed-box_dlist {
  > dt {
    float: left;
    width: 60px;
    overflow: hidden;
    clear: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
    color: var(--purple1);
    border-bottom: 1px solid var(--gray3);
    padding: 12px 0 10px 10px;
    min-height: 42px;

    @media (--gt-phone) {
      width: 75px;
      padding-top: 10px;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  > dd {
    margin: 0 0 0 60px;
    border-bottom: 1px solid var(--gray3);
    padding: 10px 10px 10px 15px;
    min-height: 42px;

    @media (--gt-phone) {
      margin-left: 75px;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &::before,
    &::after {
      display: table;
      content: ' ';
    }

    time {
      color: var(--gray6);
    }
  }
}

.shadowed-box_dlist__dd-link {
  display: block;

  i {
    float: right;
  }
}
