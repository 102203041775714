a.icon {
  padding: 5px;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  transition: background-color ease-in-out 0.15s;

  &:hover {
    color: var(--link-hover-color);
    text-decoration: none;
    background-color: var(--gray4);
  }

  i {
    margin-right: 0;
  }
}
