@define-mixin radio {
  /*
    Previously we used "display: none;" here, but due to some very weird shit in the Draft editor
    lib, we have to use this:
  */
  opacity: 0;
  width: 0;
  position: fixed;

  + span,
  + label {
    margin: 0;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: none;

    &::before {
      content: '';
      font-size: 15px;
      color: var(--primary-color);
      width: 20px;
      height: 20px;
      background-color: var(--input-background);
      border: 3px solid var(--input-border-color);
      transition: var(--input-transition);
      display: inline-block;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }

    &:hover::before {
      border-color: var(--input-hover-border-color);
    }
  }

  &:checked {
    + span,
    + label {
      &::before {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
      }

      &::after {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 2px solid var(--white);
        position: absolute;
        left: 2px;
        border-radius: 50%;
      }
    }
  }

  &:disabled + span,
  &:disabled + label {
    &::before {
      background-color: var(--gray3);
    }

    &:hover::before {
      border-color: var(--input-border-color);
    }
  }

  &:checked:disabled + span::before,
  &:checked:disabled + label::before {
    background-color: var(--gray6);
    border: 1px solid var(--gray3);
  }
}
