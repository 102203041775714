.success {
  color: var(--green4);
}

.quiet {
  color: var(--gray6);
}

.quieter {
  color: var(--gray5);
}

.danger {
  color: var(--red);
}

.alert {
  color: var(--orange);
}
