.dl_horizontal {
  > dt {
    font-weight: 500;
    line-height: 1.42857143;

    @media (--gt-largephone) {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a {
      font-weight: 300;
    }
  }

  > dd {
    @media (--gt-largephone) {
      margin-left: 180px;
    }

    &::before,
    &::after {
      display: table;
      content: ' ';
    }
  }

  &.dt_narrow {
    @media (--gt-largephone) {
      > dt {
        width: 100px;
      }

      > dd {
        margin-left: 120px;
      }
    }
  }

  &.dt_wide {
    @media (--gt-tablet) {
      > dt {
        width: 200px;
      }

      > dd {
        margin-left: 220px;
      }
    }
  }

  .dt_print,
  .dd_print {
    font-size: 0.85em;
    font-weight: 300;
  }

  .dd_print {
    font-weight: 100;
    margin-bottom: 0.25rem;
  }

  .dt_title,
  .dd_title {
    font-size: 0.85em;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
  }

  > .dl_horizontal {
    border-top: 1px dashed var(--gray4);
    padding-top: 10px;
    color: var(--gray8);
    display: none;
  }
}
