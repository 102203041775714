.row {
  @media (--gte-desktop) {
    display: flex;
    align-items: flex-start;

    .column {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
